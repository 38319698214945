<template>
  <div class="postBottomBar" @click="jumpToPage">
    <div class="numberOfReadingsArea">
      <div class="ReadingsIcon iconBox">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('views')"
          placement="bottom"
        >
          <span class="iconfont_Me icon-Views iconStyle"></span>
        </el-tooltip>
      </div>
      <div class="ReadingsIconText">{{ footerInfo.pageViewCount }}</div>
    </div>
    <div class="rightArea">
      <div class="commentBox">
        <div
          class="iconBox"
          v-if="showCommentIcon"
          @click.stop="clickCommentIcon"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('comment')"
            placement="bottom"
          >
            <span
              class="iconfont_Me iconStyle"
              :class="anyComments ? 'icon-information' : 'icon-edit1'"
            ></span>
          </el-tooltip>
        </div>
        <div class="countText" v-if="anyComments && showCommentIcon">
          {{ handleNewVersionCount(this.footerInfo.commentCount) }}
        </div>
      </div>
      <div
        class="rightAreaIconBox likesArea"
        @click.stop="changelikeStatus"
        v-loading="likeStatusLoading"
      >
        <div class="iconBox">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('thumbsNumber')"
            placement="bottom"
          >
            <span
              class="iconfont_Me iconStyle"
              :class="
                footerInfo.likeStatus ? 'icon-fabulous-fill' : 'icon-fabulous'
              "
              :style="{ color: footerInfo.likeStatus ? '#FC4C4C' : '' }"
            ></span>
          </el-tooltip>
        </div>
        <div class="countText">
          {{ handleNewVersionCount(this.footerInfo.likeCount) }}
        </div>
      </div>
      <SharePopover
        :shareConfig="shareConfig"
        @shareForMelinked="shareForMelinked"
      >
        <div
          class="iconBox rightAreaIconBox"
          @click.stop="
            () => {
              return;
            }
          "
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('share')"
            placement="bottom"
          >
            <span class="iconfont_Me icon-share iconStyle"></span>
          </el-tooltip>
        </div>
      </SharePopover>
    </div>
  </div>
</template>
<script>
import { changelikeStatus } from "@/api/newVersion";
import SharePopover from "../sharePopover";
export default {
  components: {
    SharePopover,
  },
  props: {
    footerInfo: {
      type: Object,
      default: () => {
        return {
          // 帖子浏览数
          pageViewCount: 0,
          // 评论数
          commentCount: 0,
          // 帖子的点赞数
          likeCount: 0,
          // 当前用户是否喜欢过这个帖子
          likeStatus: false,
          // 帖子ID
          gigId: "",
        };
      },
    },
    // 是否展示评论按钮
    showCommentIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      likeStatusLoading: false,
    };
  },
  computed: {
    // 分享所需要的参数
    shareConfig() {
      return {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 1,
        // 分享的内容Id
        share_id: this.footerInfo.gigId,
      };
    },
    anyComments() {
      return this.footerInfo.commentCount != 0;
    },
  },
  methods: {
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "service",
        // 分享对象的唯一ID
        uuid: this.footerInfo.gigId,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 点击评论图片
    clickCommentIcon() {
      this.$store.commit("setShowCommentInputInPostInfo", false);
      switch (this.anyComments) {
        case true:
          this.routeJump({
            name: "postContentDetails",
            params: { id: this.footerInfo.gigId, comment: true },
          });
          break;
        default:
          this.$emit("startComment");
          break;
      }
    },
    // 改变喜欢状态
    async changelikeStatus() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.footerInfo.likeStatus,
        type: this.footerInfo.type,
      };
      this.likeStatusLoading = true;
      let result = await changelikeStatus(this.footerInfo.gigId, params);
      this.likeStatusLoading = false;
      if (result.code === 201) {
        this.$emit("changelikeStatus");
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    jumpToPage() {
      this.$emit("jumpToPage");
    },
  },
};
</script>
<style lang="stylus" scoped>
.postBottomBar {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .iconBox {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .iconStyle {
      font-size: 24px;
      color: #666666;

      &:hover {
        color: #333333;
      }
    }
  }

  .countText {
    height: 30px;
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #8F8F8F;
    box-sizing: border-box;
    overflow: hidden;
  }

  .numberOfReadingsArea {
    height: 30px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;

    .ReadingsIcon {
      flex-shrink: 0;
    }

    .ReadingsIconText {
      flex: 1;
      height: 30px;
      min-width: 0;
      line-height: 30px;
      font-size: 12px;
      color: #8F8F8F;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rightArea {
    height: 30px;
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .commentBox {
      min-width: 60px;
      max-width: 70px;
      margin-right: 24px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .rightAreaIconBox {
      margin-right: 24px;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .likesArea {
      height: 30px;
      min-width: 60px;
      max-width: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
<style lang="stylus">
.likesArea {
  .el-loading-spinner {
    margin-top: -10px;

    .circular {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
