var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "postBottomBar", on: { click: _vm.jumpToPage } },
    [
      _c("div", { staticClass: "numberOfReadingsArea" }, [
        _c(
          "div",
          { staticClass: "ReadingsIcon iconBox" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.$t("views"),
                  placement: "bottom"
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-Views iconStyle" })]
            )
          ],
          1
        ),
        _c("div", { staticClass: "ReadingsIconText" }, [
          _vm._v(_vm._s(_vm.footerInfo.pageViewCount))
        ])
      ]),
      _c(
        "div",
        { staticClass: "rightArea" },
        [
          _c("div", { staticClass: "commentBox" }, [
            _vm.showCommentIcon
              ? _c(
                  "div",
                  {
                    staticClass: "iconBox",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clickCommentIcon.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("comment"),
                          placement: "bottom"
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me iconStyle",
                          class: _vm.anyComments
                            ? "icon-information"
                            : "icon-edit1"
                        })
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.anyComments && _vm.showCommentIcon
              ? _c("div", { staticClass: "countText" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.handleNewVersionCount(this.footerInfo.commentCount)
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.likeStatusLoading,
                  expression: "likeStatusLoading"
                }
              ],
              staticClass: "rightAreaIconBox likesArea",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.changelikeStatus.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "iconBox" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("thumbsNumber"),
                        placement: "bottom"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me iconStyle",
                        class: _vm.footerInfo.likeStatus
                          ? "icon-fabulous-fill"
                          : "icon-fabulous",
                        style: {
                          color: _vm.footerInfo.likeStatus ? "#FC4C4C" : ""
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "countText" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.handleNewVersionCount(this.footerInfo.likeCount)
                    ) +
                    "\n      "
                )
              ])
            ]
          ),
          _c(
            "SharePopover",
            {
              attrs: { shareConfig: _vm.shareConfig },
              on: { shareForMelinked: _vm.shareForMelinked }
            },
            [
              _c(
                "div",
                {
                  staticClass: "iconBox rightAreaIconBox",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return (() => {
                        return
                      }).apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.$t("share"),
                        placement: "bottom"
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "iconfont_Me icon-share iconStyle"
                      })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }